import { Alert, Box, BoxProps, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ShopBackground,
  ShopImage,
  ShopProductCollection,
} from '../../../../../../shop-api-client';
import {
  CartCollection,
  CartCollectionReq,
  CartDownload,
  CartDownloadReq,
  CollectionImage,
} from '../../../../../../shop-api-client/models/Cart';
import { selectBackgroundsMap } from '../../../../../redux/selectors/background.selectors';
import {
  selectEditItemAvailableImages,
  selectIsStepCompleted,
} from '../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../redux/selectors/gallery.selectors';
import { setCompletedSteps, setEditStep } from '../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import {
  addEditProductCollectionImgs,
  updateEditProductCollectionImgs,
} from '../../../../../redux/thunks/configuration.thunks';
import Modal from '../../../../../shared/components/Modal';
import ThumbnailWithBackground from '../../../../../shared/components/ThumbnailWithBackground';
import { ImageAndBackground } from '../../../../../shared/types/image';
import { SELECT_YOUR_PHOTO, SELECT_YOUR_PHOTOS } from '../../constants';
import PackagePoseModal, {
  PoseToSwap,
} from '../../PackageWizard/EditorSidebar/PackagePoseModal/PackagePoseModal';
import { getCollectionImagesStep, shapeCollectionImage } from '../../utils';
import CompletedSection from '../CompletedSection';
import EditorSectionContainer from '../EditorSectionContainer';
import ImageNodeDisplay from '../ImageNodesEditor/ImageNodeDisplay';
import SelectedImage from '../ImageSelection/SelectedImage';
import ImageSelectionPresets from '../ImageSelectionPresets';
import CollectionSelectionBackgroundWrapper from './CollectionSelectionBackgroundWrapper';

interface Props {
  containerProps?: BoxProps;
  editProduct: CartCollection | CartDownload | CartCollectionReq | CartDownloadReq;
  hideSave?: boolean;
  isInvalid?: boolean;
  poseLimit?: number;
  poseMap?: Record<string, number[]>;
  shopProduct: ShopProductCollection;
}

const CollectionSelection = ({
  containerProps,
  editProduct,
  hideSave,
  isInvalid,
  poseLimit,
  poseMap = {},
  shopProduct,
}: Props) => {
  const stepName = useMemo(() => getCollectionImagesStep(editProduct.id!), [editProduct]);

  const backgroundsMap = useAppSelector(selectBackgroundsMap);
  const availableImages = useAppSelector(selectEditItemAvailableImages);
  const { images, isGreenScreen, type: galleryType } = useAppSelector(selectGallery);
  const completed = useAppSelector(state => selectIsStepCompleted(state, stepName));

  const [error, setError] = useState(false);
  const [greenScreenImage, setGreenScreenImage] = useState('');
  const [newPose, setNewPose] = useState<PoseToSwap | null>(null);
  const [selectedCollectionImgs, setSelectedCollectionImgs] = useState<CollectionImage[]>(
    editProduct.collectionImages.filter(ci => ci.internalName),
  );

  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const poseLimitReached = poseLimit && Object.keys(poseMap).length >= poseLimit;

  useEffect(() => {
    if (shopProduct.includeAll) {
      // Mark the step as completed since all images are included
      dispatch(setCompletedSteps({ [stepName]: true }));
    }
  }, [dispatch, shopProduct.includeAll, stepName]);

  const { imageRequirementType, includeAll, minImages, maxImages } = shopProduct;

  const allImagesAreIncluded = intl.formatMessage({
    id: 'collectionSelection.allImagesAreIncluded',
    defaultMessage: 'All your photos are included.',
  });

  const selectXMorePhotos = intl.formatMessage(
    {
      id: 'collectionSelection.selectMorePhotos',
      defaultMessage: 'Select {num} more {photo}.',
    },
    {
      num: maxImages - selectedCollectionImgs.length,
      photo: maxImages - selectedCollectionImgs.length === 1 ? 'photo' : 'photos',
    },
  );

  const selectMorePhotosMsg = intl.formatMessage(
    {
      id: 'collectionSelection.selectMorePhotosMsg',
      defaultMessage:
        'Choose up to {num} pose and background combinations, then click SAVE. Scroll to view more backgrounds.',
    },
    {
      num: maxImages - selectedCollectionImgs.length,
    },
  );

  const selectMinPhotos = intl.formatMessage(
    {
      id: 'collectionSelection.selectMinPhotos',
      defaultMessage: 'You must select at least {minImages} {photo}.',
    },
    {
      minImages,
      photo: minImages === 1 ? 'photo' : 'photos',
    },
  );

  const selectMaxPhotos = intl.formatMessage(
    {
      id: 'collectionSelection.selectMaxPhotos',
      defaultMessage: 'You can select up to {maxImages} {photo}.',
    },
    {
      maxImages,
      photo: maxImages === 1 ? 'photo' : 'photos',
    },
  );

  const imagesSelected = intl.formatMessage({
    id: 'collectionSelection.imagesSelected',
    defaultMessage: 'Images Selected:',
  });

  const imagesSelectedOfMax = intl.formatMessage(
    {
      id: 'collectionSelection.imagesSelectedOfMax',
      defaultMessage: '{selected} of {max}',
    },
    {
      selected: selectedCollectionImgs.filter(ci => !!ci.internalName).length,
      max: maxImages,
    },
  );

  const imgRequirementTypeMsg = intl.formatMessage(
    {
      id: 'collectionSelection.imgRequirementTypeMsg',
      defaultMessage: 'No {imgRequirement} photos allowed.',
    },
    {
      imgRequirement: imageRequirementType === 'nonGroup' ? 'group' : 'subject',
    },
  );

  const photosSelected = intl.formatMessage(
    {
      id: 'collectionSelection.photosSelected',
      defaultMessage: '{photos} {selected}',
    },
    {
      photos: selectedCollectionImgs.length,
      selected: selectedCollectionImgs.length === 1 ? 'photo' : 'photos',
    },
  );

  const toggleCollectionImage = (image: ShopImage, background?: ShopBackground) => {
    const compareImage = (ci: CollectionImage) =>
      ci.internalName === image.internalName && (ci.backgroundID || undefined) === background?.id;

    const found = selectedCollectionImgs.some(compareImage);
    let updatedSelection: CollectionImage[] = selectedCollectionImgs.filter(
      ci => !!ci.internalName,
    );

    let shouldUpdateSelection = true;

    // Key to lookup in the existing poses map
    const key = background ? `${image.internalName}-${background.id}` : image.internalName;

    if (
      poseLimitReached &&
      !found &&
      (galleryType === 'standard' || imageRequirementType !== 'group') &&
      !poseMap[key]
    ) {
      // We need to ask the user what pose they want to replace
      setNewPose({ imageName: image.internalName, backgroundID: background?.id });
      shouldUpdateSelection = false;
    }

    if (found) {
      // `updatedSelection` is updated to de-select a collection image:
      updatedSelection = selectedCollectionImgs.reduce<CollectionImage[]>((result, ci) => {
        // If the current image is the one being de-selected...
        if (compareImage(ci)) {
          // If more than one collection image, return result to filter out the de-selected image
          if (selectedCollectionImgs.length > 1) {
            return result;
          }

          // Otherwise, the de-selected collection image gets replaced with the default entry
          result.push(shapeCollectionImage(null));
        } else {
          result.push(ci);
        }

        return result;
      }, []);
    } else if (maxImages === 1) {
      updatedSelection = [shapeCollectionImage(image, background)];
    } else {
      updatedSelection = [...updatedSelection, shapeCollectionImage(image, background)];
    }

    if (updatedSelection.length <= maxImages) {
      setSelectedCollectionImgs(updatedSelection);
    }

    if (hideSave && shouldUpdateSelection) {
      dispatch(updateEditProductCollectionImgs(editProduct.id!, updatedSelection));
    }
  };

  const handleClose = () => {
    setGreenScreenImage('');
  };

  const handleEdit = () => {
    dispatch(setEditStep(stepName));
  };

  const getHeading = () => {
    if (includeAll) {
      return undefined;
    }

    return maxImages === 1 ? SELECT_YOUR_PHOTO : SELECT_YOUR_PHOTOS;
  };

  const getSelectBackgrounds = () =>
    selectedCollectionImgs.reduce<number[]>((result, ci) => {
      if (ci.backgroundID) {
        result.push(ci.backgroundID);
      }

      return result;
    }, []);

  const getSelectedImages = () =>
    selectedCollectionImgs.reduce<Record<string, ImageAndBackground>>((map, ci) => {
      // Explicitly use `undefined` instead of `null` here
      // This will allow ImageNodeDisplay to correctly associate the image
      const key = `${ci.internalName}-${ci.backgroundID || 'undefined'}`;
      map[key] = { imageName: ci.internalName!, backgroundID: ci.backgroundID };
      return map;
    }, {});

  const handleSwapPose = () => {
    if (!newPose) {
      return;
    }

    const { imageName, backgroundID } = newPose;

    const found = selectedCollectionImgs.some(
      ci => ci.internalName === imageName && ci.backgroundID == backgroundID,
    );

    // Add the new pose if it wasn't added to the collection yet:
    if (!found) {
      dispatch(
        addEditProductCollectionImgs(
          editProduct.id!,
          shapeCollectionImage(
            images[imageName],
            backgroundID ? backgroundsMap[backgroundID] : undefined,
          ),
        ),
      );
    }
  };

  const selectImage = (image: ShopImage, background?: ShopBackground) => {
    if ((image && background) || !image.isGreenScreen || !isGreenScreen) {
      toggleCollectionImage(image, background);
    } else {
      // if we are here, it is GS and needs a bg selection
      setGreenScreenImage(image.internalName);
    }
  };

  const saveCollectionSelection = () => {
    const imageCount = selectedCollectionImgs.length;

    // validate meets min # of images
    if (imageCount < minImages || imageCount > maxImages) {
      setError(true);
      return;
    }

    // if valid, update the collection images on editproduct
    dispatch(updateEditProductCollectionImgs(editProduct.id!, selectedCollectionImgs));
    dispatch(setEditStep(null));
    dispatch(setCompletedSteps({ [stepName]: true }));
  };

  const renderCompletedImagePreview = () => {
    const { backgroundID, internalName } = selectedCollectionImgs[0];

    return (
      <SelectedImage
        background={backgroundID ? backgroundsMap[backgroundID] : undefined}
        image={internalName ? images[internalName] : undefined}
      />
    );
  };

  if (completed) {
    return (
      <CompletedSection
        heading={SELECT_YOUR_PHOTO}
        onEdit={includeAll ? undefined : handleEdit}
        contentProps={{ paddingTop: includeAll ? 1.5 : 0 }}
      >
        <Flex direction="column">
          {(includeAll || maxImages > 1) && (
            <Text>{includeAll ? allImagesAreIncluded : photosSelected}</Text>
          )}
          {!includeAll && maxImages === 1 && renderCompletedImagePreview()}
        </Flex>
      </CompletedSection>
    );
  }

  const renderExplanation = () => {
    return (
      <Alert variant="info" borderRadius="md" marginY={4}>
        <Box lineHeight="short">
          {includeAll ? (
            <Text variant="inline" marginRight={1}>
              {allImagesAreIncluded}
            </Text>
          ) : (
            <>
              <Text variant="inline" marginRight={1}>
                {selectMinPhotos}
              </Text>
              <Text variant="inline" fontFamily="ITC Avant Garde Gothic Book" marginRight={1}>
                {selectMaxPhotos}
              </Text>
              {imageRequirementType !== 'any' && (
                <Text variant="inline" fontFamily="ITC Avant Garde Gothic Book">
                  {imgRequirementTypeMsg}
                </Text>
              )}
            </>
          )}
        </Box>
      </Alert>
    );
  };

  const renderImageDisplay = () => {
    if (includeAll) {
      return null;
    }

    const images = availableImages.products[editProduct.id!];
    if (images.length === 1) {
      return (
        <ImageSelectionPresets
          images={images}
          selectImage={selectImage}
          selectedImages={getSelectedImages()}
          selectedBackgrounds={getSelectBackgrounds()}
        />
      );
    }

    return (
      <ImageNodeDisplay
        imageRequirementType={imageRequirementType}
        selectImage={selectImage}
        selectedImages={getSelectedImages()}
      />
    );
  };

  const renderSelections = () => {
    if (includeAll) {
      // Selections aren't rendered when all images are included
      return null;
    }

    return (
      <>
        <Flex alignItems="baseline">
          <Text fontFamily="ITC Avant Garde Gothic Demi" marginRight={1} marginBottom={3}>
            {imagesSelected}
          </Text>
          <Text fontFamily="ITC Avant Garde Gothic Book">{imagesSelectedOfMax}</Text>
        </Flex>
        <Flex direction="row" wrap="wrap">
          {[...Array(maxImages)].map((imgSlot, index) => {
            const img = images[selectedCollectionImgs[index]?.internalName!];
            const bg = backgroundsMap[selectedCollectionImgs[index]?.backgroundID || ''];

            if (img) {
              return (
                <Box key={index} height="40px" width="40px" marginRight={2} marginBottom={2}>
                  <ThumbnailWithBackground
                    src={img.sources.thumb}
                    background={bg?.sources?.thumb}
                    objectFit="cover"
                    height="40px"
                    width="40px"
                    borderRadius="md"
                  />
                </Box>
              );
            }

            let borderColor = index < minImages ? 'grey.4' : 'grey.2';
            if (error || isInvalid) {
              borderColor = 'error';
            }

            return (
              <Box
                key={index}
                borderStyle="dashed"
                borderColor={borderColor}
                borderRadius="md"
                borderWidth="2px"
                h="40px"
                marginRight={2}
                marginBottom={2}
                w="40px"
              />
            );
          })}
        </Flex>
      </>
    );
  };

  const renderPoseModal = () => {
    if (!newPose) {
      return null;
    }

    return (
      <PackagePoseModal
        onClose={() => setNewPose(null)}
        onConfirm={handleSwapPose}
        poseMap={poseMap}
        newPose={newPose}
      />
    );
  };

  return (
    <>
      <EditorSectionContainer
        heading={getHeading()}
        hideBorder
        onSave={!hideSave ? saveCollectionSelection : undefined}
        marginBottom={0}
        paddingBottom={0}
        {...containerProps}
      >
        {renderExplanation()}
        {renderSelections()}
      </EditorSectionContainer>
      <Box borderBottomColor="grey.2" borderBottomWidth="1px" paddingBottom={7}>
        {renderImageDisplay()}
      </Box>
      <Modal
        isOpen={!!greenScreenImage}
        onClose={handleClose}
        onConfirm={handleClose}
        heading={selectXMorePhotos}
        subHeading={selectMorePhotosMsg}
        size={isMobile ? 'full' : '3xl'}
        contentStyles={{
          margin: undefined,
          maxWidth: undefined,
          maxHeight: undefined,
          minHeight: undefined,
          height: isMobile ? undefined : window.innerHeight - 200,
        }}
      >
        <CollectionSelectionBackgroundWrapper
          columns={4}
          image={images[greenScreenImage]}
          collectionImages={selectedCollectionImgs}
          addOrDelFromCollection={toggleCollectionImage}
        />
      </Modal>
      {renderPoseModal()}
    </>
  );
};

export default CollectionSelection;
