import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { productHasNoOrientation } from 'iq-product-render';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ShopBackground,
  ShopImage,
  ShopProduct,
  ShopProductPrint,
} from '../../../../../../../shop-api-client';
import {
  CartImageNodeCrop,
  CartImageNodeReq,
  CartPrintProductReq,
  ImageTone,
} from '../../../../../../../shop-api-client/models/Cart';
import {
  selectAllBackgrounds,
  selectBackgroundsMap,
} from '../../../../../../redux/selectors/background.selectors';
import { selectCatalog } from '../../../../../../redux/selectors/catalog.selectors';
import {
  selectConfiguration,
  selectEditItemAvailableImages,
  selectPackageItemMap,
} from '../../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../../redux/selectors/gallery.selectors';
import { selectPriceSheet } from '../../../../../../redux/selectors/priceSheet.selectors';
import { setEditNodeID } from '../../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import {
  updateEditProductNode,
  updatePoseInPackage,
} from '../../../../../../redux/thunks/configuration.thunks';
import ImageStepHeadings from '../../../../../../shared/components/ImageStepHeadings';
import { Step } from '../../../../../../shared/components/ImageStepHeadings/ImageStepHeadings';
import useIsMobile from '../../../../../../shared/hooks/useIsMobile';
import {
  CONFIRM_YOUR_CROP,
  MULTI_NODE_IMAGE_WARNING,
  SELECT_YOUR_PHOTO,
  THUMB_GRID_MAX_HEIGHT,
  THUMB_GRID_MAX_WIDTH,
} from '../../../constants';
import CropEditor from '../../../shared/CropEditor';
import { getCropMobileBuffer } from '../../../shared/CropEditor/utils';
import ImageNodeBackgroundDisplay from '../../../shared/ImageNodesEditor/ImageNodeBackgroundDisplay';
import ImageNodeDisplay from '../../../shared/ImageNodesEditor/ImageNodeDisplay';
import ImageNodeToneDisplay from '../../../shared/ImageNodesEditor/ImageNodeToneDisplay';
import ImageSelectionPresets from '../../../shared/ImageSelectionPresets';
import {
  getBackgroundForPoseSwap,
  getConfigurableImageNodes,
  getImageCropStep,
  getImageNodesStep,
  getProductToNodeKey,
  shapeCartImageNode,
} from '../../../utils';
import PackagePoseModal from '../PackagePoseModal';
import { PoseToSwap } from '../PackagePoseModal/PackagePoseModal';

interface Props {
  activeItem: CartPrintProductReq;
  poseLimit: number | undefined;
  poseMap: Record<string, number[]>;
}

const PackageSingleNode = ({ activeItem, poseLimit, poseMap }: Props) => {
  const availableImages = useAppSelector(selectEditItemAvailableImages);
  const backgroundsMap = useAppSelector(selectBackgroundsMap);
  const orderedBackgrounds = useAppSelector(selectAllBackgrounds);
  const { rendererProductMap } = useAppSelector(selectCatalog);
  const { editNodeID, editPackage, editPackageStep, invalidSteps } =
    useAppSelector(selectConfiguration);
  const { productNodeMap } = useAppSelector(selectPriceSheet);
  const {
    images,
    isGreenScreen,
    settings: { showBwAndSepia },
    type: galleryType,
  } = useAppSelector(selectGallery);
  const packageProductMap = useAppSelector(selectPackageItemMap);
  const dispatch = useAppDispatch();

  const shopProduct = packageProductMap[activeItem.priceSheetItemID];
  const poseMapKeys = Object.keys(poseMap);
  const poseLimitReached = poseLimit && poseMapKeys.length >= poseLimit;
  const isSinglePose = poseLimit === 1;

  const { current: catalogNodes } = useRef(
    getConfigurableImageNodes(
      shopProduct as ShopProductPrint,
      productNodeMap[(shopProduct as ShopProduct).catalogProductID],
    ),
  );

  const [activeStep, setActiveStep] = useState<Step>('photo');
  const [poseHadBackground, setPoseHadBackground] = useState(true);
  const [newPose, setNewPose] = useState<PoseToSwap | null>(null);
  const [showWarning, setShowWarning] = useState(false);

  const isMobile = useIsMobile();
  const nodes = activeItem.nodes.filter(node => node.type === 'image') as CartImageNodeReq[];

  // Pull the Cart & Catalog Node
  const node = editNodeID ? nodes.find(node => node.catalogNodeID === editNodeID) : undefined;
  const catalogNode = node
    ? catalogNodes.find(catalogNode => catalogNode.id === node.catalogNodeID)
    : undefined;

  // Calculate the steps based off the current item, and which of the steps we're on:
  const { steps, disabledSteps } = useMemo(() => {
    const steps: Step[] = ['photo'];

    // For green screen galleries, make sure there's at least one png available, and no preset image

    const nodeImages = catalogNode
      ? availableImages.nodes[getProductToNodeKey(shopProduct.id, catalogNode.id)]
      : [];
    if (
      nodeImages.length !== 1 &&
      nodeImages.some(image => image.isGreenScreen && !catalogNode?.skipBackgroundSelection)
    ) {
      steps.push('background');
    }

    if (showBwAndSepia && !isGreenScreen) {
      steps.push('effects');
    }

    // The disabled state depends on:
    // - If the user clicked on a step other than "photo" before selecting an image: showWarning
    // - For the background step - a non-green screen image was selected
    const disabledSteps: Step[] = [];

    if (showWarning && !node?.imageDisplayName) {
      disabledSteps.push('background', 'effects');
    } else if (
      (node?.imageInternalName && !images[node.imageInternalName].isGreenScreen) ||
      catalogNode?.skipBackgroundSelection
    ) {
      disabledSteps.push('background');
    }

    return {
      catalogNode,
      disabledSteps,
      node,
      nodes,
      shopProduct,
      steps,
    };
  }, [
    availableImages.nodes,
    catalogNode,
    images,
    isGreenScreen,
    node,
    nodes,
    shopProduct,
    showBwAndSepia,
    showWarning,
  ]);

  const imageNodeStepName = getImageNodesStep(activeItem.id!);
  const cropStepName = getImageCropStep(activeItem.id!);

  useEffect(() => {
    if (catalogNodes.length) {
      dispatch(setEditNodeID(catalogNodes[0].id));
    }
  }, [catalogNodes, dispatch]);

  useEffect(() => {
    if (editPackageStep) {
      setShowWarning(invalidSteps[editPackageStep]);
    }
  }, [editPackageStep, invalidSteps]);

  useEffect(() => {
    // Prevent window zoom during crop:
    const cropStepName = getImageCropStep(activeItem.id!);
    const disableWindowZoom = (e: TouchEvent) => {
      if (editPackageStep === cropStepName) {
        e.preventDefault();
      }
    };

    window.addEventListener('touchmove', disableWindowZoom, { passive: false });

    return () => {
      window.removeEventListener('touchmove', disableWindowZoom);
    };
  }, [activeItem.id, editPackageStep]);

  const handleChangeCrop = useCallback(
    (crop: CartImageNodeCrop) => {
      if (!node || !activeItem) {
        return;
      }

      dispatch(
        updateEditProductNode(activeItem.id!, {
          ...node,
          ...crop,
        }),
      );
    },
    [activeItem, dispatch, node],
  );

  if (!catalogNode || !node || !editPackage || !editPackageStep) {
    return null;
  }

  /**
   * Update an image/background selection in a single-node package product.
   *
   * Note: this function is identical to `MultiNodeConfiguration.handleSelectImage`
   */
  const handleSelectImage = (image: ShopImage, background?: ShopBackground) => {
    if (!catalogNode) {
      return;
    }

    if (showWarning) {
      setShowWarning(false);
    }

    let selectedBackground = background;
    const skipBackground = catalogNode.skipBackgroundSelection;
    // If no background was passed, but this is a green screen image...
    if (!background && image.isGreenScreen && !skipBackground) {
      // Find whether the image is already in the poseMap:
      const existingKey = poseMapKeys.find(k => k.includes(`${image.internalName}-`));

      // If a key is found for the image, grab the background id to set selectedBackground.
      // This is done so that defaulting to the first background will not potentially count
      // toward, or exceed, the visitor's allowed pose count.
      if (existingKey) {
        const backgroundID = existingKey.split('-')[1];
        selectedBackground = backgroundsMap[backgroundID];
      } else {
        // Default to the first background of the first set
        selectedBackground = orderedBackgrounds[0];
      }
    }

    let shouldUpdateNode = true;

    // Create two keys, one pointing to the old image assigned to the node, one to the newly selected one
    const { imageInternalName, backgroundID } = node;
    const prevKey = backgroundID ? `${imageInternalName}-${backgroundID}` : imageInternalName;
    const newKey = selectedBackground
      ? `${image.internalName}-${selectedBackground.id}`
      : image.internalName;

    if (
      poseLimitReached &&
      (galleryType === 'standard' || catalogNode.imageRequirementType !== 'group')
    ) {
      const { imageInternalName, backgroundID } = node;
      const isExclusiveToProduct =
        prevKey &&
        poseMap[prevKey] &&
        poseMap[prevKey].length === 1 &&
        poseMap[prevKey][0] === activeItem.id!;

      // Automatically update the pose if:
      // - This is a single pose package
      // - This product is the only one that uses the old image
      if (isExclusiveToProduct) {
        const nextBackgroundID = getBackgroundForPoseSwap(
          poseMapKeys,
          selectedBackground?.id,
          skipBackground,
        );

        dispatch(
          updatePoseInPackage(
            imageInternalName!,
            backgroundID,
            image.internalName,
            nextBackgroundID,
            isSinglePose,
          ),
        );
      } else if (!poseMap[newKey]) {
        // We need to ask the user what pose they want to replace
        setNewPose({ imageName: image.internalName, backgroundID: selectedBackground?.id });
        setPoseHadBackground(!!background);
        shouldUpdateNode = false;
      }
    }

    if (shouldUpdateNode) {
      const shapedImgNode = shapeCartImageNode(catalogNode, image, selectedBackground, 'original');
      dispatch(updateEditProductNode(activeItem.id!, shapedImgNode, true));

      if (image.isGreenScreen && !background && !catalogNode.skipBackgroundSelection) {
        setActiveStep('background');
      }
    }
  };

  const handleSelectStep = (step: Step) => {
    // If no image has been selected; set the error state;
    if (!node.imageInternalName) {
      setShowWarning(true);
      return;
    }

    setActiveStep(step);
  };

  const handleSelectTone = (imageTone: ImageTone) => {
    dispatch(updateEditProductNode(activeItem.id!, { ...node, imageTone }));
  };

  const handleSwapPose = () => {
    if (!newPose) {
      return;
    }

    const { imageName, backgroundID } = newPose;
    const image = images[imageName];
    const background = backgroundID ? backgroundsMap[backgroundID] : undefined;

    const shapedImgNode = shapeCartImageNode(catalogNode, image, background, 'original');
    dispatch(updateEditProductNode(activeItem.id!, shapedImgNode));

    if (image.isGreenScreen && !poseHadBackground) {
      setActiveStep('background');
    }
  };

  const renderCropStep = () => {
    if (editPackageStep !== cropStepName || !node.imageInternalName) {
      return null;
    }

    const crop: CartImageNodeCrop = {
      cropH: node.cropH!,
      cropW: node.cropW!,
      cropX: node.cropX!,
      cropY: node.cropY!,
      orientation: node.orientation!,
    };

    const catalogProduct = rendererProductMap[shopProduct.catalogProductID];
    const flipSingleImageNode =
      productHasNoOrientation(catalogProduct) && catalogProduct.nodes[0].type === 'image';

    return (
      <>
        <Heading size="md" marginX="auto">
          {CONFIRM_YOUR_CROP}
        </Heading>
        <Flex width="100%" height="100%" flex={1} padding={2}>
          <CropEditor
            flipSingleImageNode={flipSingleImageNode}
            handleCrop={handleChangeCrop}
            imageNode={catalogNode}
            initialCrop={crop}
            shopBackground={node.backgroundID ? backgroundsMap[node.backgroundID] : null}
            shopImage={images[node.imageInternalName]}
            singleNodeMaxWidth={
              isMobile
                ? window.innerWidth -
                  getCropMobileBuffer(
                    { 600: 140, 700: 100, base: 40 },
                    catalogNode.width / catalogNode.height > 1 ? { base: 50 } : undefined,
                  )
                : 375
            }
            forceHeight={isMobile}
          />
        </Flex>
      </>
    );
  };

  const renderImageHeadings = () => {
    if (editPackageStep !== imageNodeStepName) {
      return null;
    }

    return (
      <>
        {!isMobile && (
          <Heading size="md" marginX="auto">
            {SELECT_YOUR_PHOTO}
          </Heading>
        )}
        <ImageStepHeadings
          activeStep={activeStep}
          disabled={disabledSteps}
          handleSelectStep={handleSelectStep}
          position="top"
          steps={steps}
        />
      </>
    );
  };

  const renderImageNodeStep = () => {
    if (editPackageStep !== imageNodeStepName) {
      return null;
    }

    if (activeStep === 'photo') {
      // In multi-node we only show the selected image for the currently selected node
      // highlighted in blue, everything else uses a counter:
      const selectedImages = node.imageInternalName
        ? {
            [`${node.imageInternalName}-${node.backgroundID || undefined}`]: {
              imageName: node.imageInternalName,
              backgroundID: node.backgroundID || undefined,
            },
          }
        : {};

      const selectedImagesCounter = nodes.reduce<Record<string, number>>((result, n) => {
        // Only add images that don't match the currently selected one
        if (n.imageInternalName && n.imageInternalName !== node.imageInternalName) {
          result[n.imageInternalName] = (result[n.imageInternalName] || 0) + 1;

          // If there is a background, also add that:
          if (n.backgroundID) {
            const imageKey = `${n.imageInternalName}-${n.backgroundID}`;
            result[imageKey] = (result[imageKey] || 0) + 1;
          }
        }

        return result;
      }, {});

      const nodeImages = availableImages.nodes[getProductToNodeKey(shopProduct.id, catalogNode.id)];
      if (nodeImages.length === 1) {
        return (
          <ImageSelectionPresets
            error={showWarning}
            imageMaxWidth={isMobile ? THUMB_GRID_MAX_WIDTH : '100px'}
            imageMaxHeight={THUMB_GRID_MAX_HEIGHT}
            images={nodeImages}
            selectedBackgrounds={node.backgroundID ? [node.backgroundID] : []}
            selectImage={handleSelectImage}
            selectedImageName={node.imageInternalName}
            skipBackgroundSelection={catalogNode?.skipBackgroundSelection}
          />
        );
      }

      // If there's a pose limit, only show those images:
      return (
        <ImageNodeDisplay
          imageMaxWidth={isMobile ? THUMB_GRID_MAX_WIDTH : '100px'}
          imageMaxHeight={THUMB_GRID_MAX_HEIGHT}
          imageRequirementType={catalogNode.imageRequirementType}
          selectedImagesCounter={selectedImagesCounter}
          selectedImages={selectedImages}
          selectImage={handleSelectImage}
          showErrorOutline={showWarning}
          skipBackgroundSelection={catalogNode.skipBackgroundSelection}
        />
      );
    }

    // All other steps require a selected image
    if (!node.imageInternalName) {
      return null;
    }

    if (activeStep === 'background') {
      return (
        <ImageNodeBackgroundDisplay
          columns={3}
          image={images[node.imageInternalName]}
          selectBackground={handleSelectImage}
          selectedBackgrounds={node.backgroundID ? [node.backgroundID] : []}
          showBackgroundName={false}
        />
      );
    }

    if (activeStep === 'effects') {
      return (
        <Box paddingX={2} paddingTop={2}>
          <Text fontFamily="heading" fontSize="14px">
            All Effects (3)
          </Text>
          <ImageNodeToneDisplay
            selectedTone={node.imageTone || 'original'}
            selectTone={handleSelectTone}
            useOffWhite={false}
          />
        </Box>
      );
    }
  };

  const renderPoseModal = () => {
    if (!newPose) {
      return null;
    }

    return (
      <PackagePoseModal
        catalogNode={catalogNode}
        onClose={() => setNewPose(null)}
        onConfirm={handleSwapPose}
        poseMap={poseMap}
        newPose={newPose}
      />
    );
  };

  const renderWarning = () => {
    if (!showWarning) {
      return null;
    }

    return (
      <Text color="error" paddingTop={4}>
        {MULTI_NODE_IMAGE_WARNING}
      </Text>
    );
  };

  if (isMobile) {
    return (
      <Flex direction="column" flex={1}>
        {renderImageHeadings()}
        {renderWarning()}
        {renderImageNodeStep()}
        {renderCropStep()}
        {renderPoseModal()}
      </Flex>
    );
  }

  return (
    <>
      <Flex direction="column" flex={1}>
        {renderImageHeadings()}
        {renderWarning()}
        {renderImageNodeStep()}
        {renderCropStep()}
        {renderPoseModal()}
      </Flex>
    </>
  );
};

export default PackageSingleNode;
